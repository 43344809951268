import React, { useState } from 'react';
import { BsDash } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import TrainingProgress from './TrainingProgress';
import TrainingCalendar from './Training Calendar';

const TrainingDetails = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to show/hide content
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
  <div style={{padding:'20px', marginTop: '1.5em'}}>
    <div style={{fontSize:'28px',fontWeight:'bold'}}>My Trainings</div>
    <div style={{ margin: 'auto', fontFamily: 'Arial, sans-serif', padding: '20px', backgroundColor: 'white', marginTop: '1.5em', borderRadius: '10px' }}>
      {/* Header section with toggle button */}
      <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Training Registered</p>
      
      {/* Search bar section */}
      <div style={{ marginBottom: '20px' }}>
        <input 
          type="text" 
          placeholder="Search trainings..." 
          style={{
            width: '100%', 
            padding: '10px', 
            border: '1px solid #3F88A5', 
            borderRadius: '10px', 
            fontSize: '16px'
          }} 
        />
      </div>
      
      <div 
        style={{
          backgroundColor: '#E8F4FA', 
          padding: '10px', 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '5px'
        }}
      >
        <span>Online mandatory trainings (global) - Arun Pinto</span>
        <button 
          onClick={toggleOpen} 
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            fontSize: '24px',
            fontWeight: 'bold',
            transform: isOpen ? 'rotate(0deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease'
          }}
        >
          {isOpen ? <BsDash /> : <IoMdAdd />}
        </button>
      </div>

      {/* Collapsible details section */}
      {isOpen && (
        <div 
          style={{
            border: '1px solid #ccc', 
            borderRadius: '5px', 
            marginTop: '10px', 
            padding: '15px'
          }}
        >
          <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
            <thead>
              <tr>
                <th>Trainer Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Venue</th>
                <th>Link For The Meeting</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Arun Pinto</td>
                <td>17/04/2024</td>
                <td>10:00 AM to 12:00 PM</td>
                <td>Virtual</td>
                <td><a href="https://www.site.com">www.site.com</a></td>
              </tr>
            </tbody>
          </table>

          <hr />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '48%' }}>
              <h6>Training Description</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut orci tellus, commodo vitae porttitor ac, efficitur sit amet nibh.</p>
            </div>
            <div style={{ width: '48%' }}>
              <h6>Training Agenda</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut orci tellus, commodo vitae porttitor ac, efficitur sit amet nibh.</p>
            </div>
          </div>
        </div>

        
        
      )}
      
    </div>

    

<div className='row mt-5' >
    <div className='col-8'>
<TrainingProgress/>
    </div>
    <div className='col-4'>
<TrainingCalendar/>
    </div>

</div>

  </div>
  );
};

export default TrainingDetails;
