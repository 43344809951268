import React, { useState } from "react";
import "./SignUpTrainee.css";

const SignUpTrainee = ({ toggleSignIn }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    employeeId: "",
    email: "",
    password: "",
    gender: "Male",
    category: "",
    department: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle registration logic here
    console.log("Registration Data", formData);
  };

  return (
    <div className="sign-in-container">
      <div className="sign-in-box">
        <button className="close-btn">✕</button>
        <h2>Register Now</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Enter First Name"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Enter Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="employeeId">Employee ID</label>
            <input
              type="text"
              name="employeeId"
              id="employeeId"
              placeholder="Enter Employee Unique ID"
              value={formData.employeeId}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email ID</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter Mail ID"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="gender">Gender</label>
            <select
              name="gender"
              id="gender"
              value={formData.gender}
              onChange={handleInputChange}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="category">Category</label>
            <select
              name="category"
              id="category"
              value={formData.category}
              onChange={handleInputChange}
              required
            >
              <option value="">Select category</option>
              <option value="Category1">Category 1</option>
              <option value="Category2">Category 2</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="department">Business/Department</label>
            <select
              name="department"
              id="department"
              value={formData.department}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Business</option>
              <option value="Department1">Department 1</option>
              <option value="Department2">Department 2</option>
            </select>
          </div>
          <button type="submit" className="login-btn">
            REGISTER
          </button>
        </form>
        <div className="register-section">
          <p>Already registered?</p>
          <button className="register-btn" onClick={toggleSignIn}>
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpTrainee;
