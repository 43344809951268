import React, { useState } from "react";
import "./SignUpTrainee.css";

const SignInTrainee = ({ toggleRegister }) => {
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // handle login logic here
        console.log("Form Submitted", formData);
    };

    return (
        <div className="sign-in-container">
            <div className="sign-in-box">
                <button className="close-btn">✕</button>
                <h2>Sign In</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="username">User name/ email id</label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit" className="login-btn">
                        LOGIN
                    </button>
                </form>
                <div className="register-section">
                    <p>Not registered?</p>
                    <button className="register-btn" onClick={toggleRegister}>
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignInTrainee;
