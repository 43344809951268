import React from "react";
import { Card } from "react-bootstrap";
import "./TrainingStats.css"; // Custom CSS file
import view from "../img/ViewIcon.png";
import arroweTick from "../img/arroweTick.png";

const TrainingStats = () => {
  return (
    <div className="training-stats-grid ">
      <Card className="training-stat-box " style={{borderRadius:'10px'}}>
        <Card.Body>
        <div className="training-stat-content">
          <div className="training-stat-info">
            <div>
              <h4>Number Of Trainings Enrolled</h4>
            </div>
            <div className="icon">
              <img src={view} alt="view" />
            </div>
          </div>
          <p className="training-stat-number">15</p>
        </div>
        </Card.Body>
      </Card>

      <Card className="training-stat-box" style={{borderRadius:'10px'}}>
        <Card.Body>
          <div className="training-stat-content">
            <div className="training-stat-info">
              <h4>Number Of Trainings Attended</h4>
              <div className="icon">
              <img src={arroweTick} alt="view" />

              </div>
            </div>
            <p className="training-stat-number">06</p>
          </div>
        </Card.Body>
      </Card>

      <Card className="training-stat-box" style={{borderRadius:'10px'}}>
        <Card.Body>
          <div className="training-stat-content">
            <div className="training-stat-info">
              <h4>Number Of Trainings Completed</h4>
              <div className="icon">
              <img src={arroweTick} alt="view" />

              </div>
            </div>
            <p className="training-stat-number">03</p>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TrainingStats;
