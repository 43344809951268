import React, { useEffect, useState } from "react";
import close from "../img/Close.png";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";

const TrainingFilterModal = ({ onClose, topicMapping, setTopicMapping,trainingTopicData }) => {
  const [selectedAlphabet, setSelectedAlphabet] = useState("A");
  // const [trainingTopicData, setTrainingTopicData] = useState([]);

  const handleAlphabetClick = (e,alphabet) => {
    e.preventDefault();
    setSelectedAlphabet(alphabet);
  };

  const handleTopicChange = (topic) => {
    const isAlreadySelected = topicMapping.some(
      (selectedTopic) => selectedTopic.id === topic.id
    );

    let updatedTopics;
    if (isAlreadySelected) {
      updatedTopics = topicMapping.filter(
        (selectedTopic) => selectedTopic.id !== topic.id
      );
    } else {
      updatedTopics = [...topicMapping, topic];
    }

    setTopicMapping(updatedTopics); 
  };

  const filteredTopics = trainingTopicData.filter((topic) =>
    topic.topic && topic.topic.startsWith(selectedAlphabet)
  );

  const isTopicSelected = (topicId) =>
    topicMapping.some((selectedTopic) => selectedTopic.id === topicId);

  return (
    <div className="modal-backdrop" style={modalBackdropStyles()}>
      <div className="modal-content" style={modalContentStyles()}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1em",
          }}
        >
          <div>
            <h2 style={headingStyle()}>Topic Filter</h2>
          </div>
          <div>
            <img
              src={close}
              alt="close"
              style={{ width: "20px", cursor: "pointer" }}
              onClick={onClose}
            />
          </div>
        </div>

        <div style={alphabetContainerStyle()}>
          {[
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
          ].map((alphabet) => (
            <button
              key={alphabet}
              style={alphabetButtonStyle(alphabet === selectedAlphabet)}
              onClick={(e) => handleAlphabetClick(e,alphabet)}
            >
              {alphabet}
            </button>
          ))}
        </div>

        <div style={trainingTopicContainerStyle()}>
          <h4>Training Topic</h4>
          {filteredTopics.length > 0 ? (
            filteredTopics.map((topic) => (
              <div key={topic.id} style={{ marginBottom: "10px" }}>
                <label>
                  <input
                    type="checkbox"
                    style={checkboxStyle()}
                    checked={isTopicSelected(topic.id)}
                    onChange={() => handleTopicChange(topic)}
                  />
                  {topic?.topic}
                </label>
              </div>
            ))
          ) : (
            <p>No topics available for {selectedAlphabet}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const modalBackdropStyles = () => ({
  position: "fixed",
  top: "20%",
  left: 0,
  width: "100%",
  height: "50vh",
  background: "rgba(0,0,0,0.0)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const modalContentStyles = () => ({
  background: "white",
  padding: "20px",
  borderRadius: "5px",
  width: "50%",
  overflowY: "auto",
});

const headingStyle = () => ({
  fontSize: "24px",
  marginBottom: "15px",
});

const alphabetContainerStyle = () => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
});

const alphabetButtonStyle = (isActive) => ({
  background: isActive ? "#3F88A5" : "white",
  color: isActive ? "white" : "#3F88A5",
  border: isActive ? "1px solid #3F88A5" : "1px solid #3F88A5",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  margin: "0 5px",
});

const trainingTopicContainerStyle = () => ({
  marginBottom: "20px",
});

const checkboxStyle = () => ({
  width: "15px",
  height: "15px",
  transform: "scale(1.5)",
  marginRight: "10px",
  border: "2px solid #3F88A5",
  backgroundColor: "#3F88A5",
  borderRadius: "4px",
  position: "relative",
  "&:checked": {
    backgroundColor: "#3F88A5",
    color: "#3F88A5",
  },
});

export default TrainingFilterModal;
