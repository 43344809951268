import React from 'react'
// import TrainingRegistrationStatus from './TrainingRegistrationStatus'
import DonutChart from "./DonutChart";

function Training() {
  return (
  <div className='row' style={{height:'50vh'}} >
      <div className='col-5'>
      {/* <TrainingRegistrationStatus /> */}
      <DonutChart/>
    </div>
    <div className='col-7'>
        {/* <TrainingCatalogue/> */}
    </div>
  </div>
  )
}

export default Training
